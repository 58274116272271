import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PaginateResponse } from '@app/core/models/paginate-response.model';
import { Profile } from '@app/core/models/profile.model';
import { CreateUser } from '@app/core/models/requests/create-user.model';
import { ResponseModel } from '@app/core/models/response.model';
import { User } from '@app/core/models/user.model';
import { environment } from '@env/environment';
import { catchError, Observable, of, switchMap } from 'rxjs';

@Injectable()
export class UserService {
	private URL: string = environment.apiUrl + 'users/';

	/**
	 * Constructor
	 */
	constructor(@Inject(HttpClient) private http: HttpClient) { }

	getProfile(): Observable<Profile> {
		return this.http.get(this.URL + 'profile').pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<Profile>;
				return of(res.data);
			}),
		);
	}

	updateProperty(request: { value: string; prop: string }) {
		return this.http.post(this.URL + 'update-property', request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	updatePassword(request: {
		password: string;
		newPassword: string;
		confirmPassword: string;
	}) {
		return this.http.post(this.URL + 'update-password', request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	getUsersActives(paginate: {
		search: string;
		page: number;
		size: number;
	}): Observable<PaginateResponse<User>> {
		const api = this.URL;
		const { search, page, size } = paginate;

		return this.http
			.get(`${api}all-actives?page=${page}&size=${size}&search=${search}`)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<PaginateResponse<User>>;
					return of(res.data);
				}),
			);
	}

	getUsersInactives(paginate: {
		search: string;
		page: number;
		size: number;
	}): Observable<PaginateResponse<User>> {
		const api = this.URL;
		const { search, page, size } = paginate;

		return this.http
			.get(`${api}all-inactives?page=${page}&size=${size}&search=${search}`)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<PaginateResponse<User>>;
					return of(res.data);
				}),
			);
	}

	/**
	 * Create new user.
	 * @param request Object to create
	 * @returns {boolean};
	 */
	createUser(request: CreateUser) {
		const { URL } = this;
		return this.http.post(`${URL}create`, request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	updateUser(request: CreateUser) {
		const { URL } = this;
		return this.http.post(`${URL}update`, request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	deleteUser(username: string) {
		const { URL } = this;
		return this.http
			.post(`${URL}delete`, {
				username: username,
			})
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<boolean>;
					return of(res.data);
				}),
			);
	}

	/**
	 * Get user by username.
	 * @param username Username from user
	 * @returns {Observable<boolean>}
	 */
	getUserByUsername(username: string): Observable<User> {
		const { URL } = this;
		return this.http.get(`${URL}get-user?username=${username}`).pipe(
			switchMap((response) => {
				const res = response as ResponseModel<User>;
				return of(res.data);
			}),
		);
	}

	updateTwoFactorAuth(twoFA: boolean): Observable<boolean> {
		const { URL } = this;
		return this.http.post(`${URL}update-two-factor`, { twoFactor: twoFA }).pipe(
			switchMap((response) => {
				const res = response as ResponseModel<boolean>;
				return of(res.data);
			}),
		);
	}

	updateEmailNotifications(emailNotify: boolean): Observable<boolean> {
		const { URL } = this;
		return this.http
			.post(`${URL}update-email-notifications`, {
				emailNotifications: emailNotify,
			})
			.pipe(
				switchMap((response) => {
					const res = response as ResponseModel<boolean>;
					return of(res.data);
				}),
			);
	}

	updateShowDocuments(showDocuments: boolean): Observable<boolean> {
		const { URL } = this;
		return this.http
			.post(`${URL}update-show-documents`, { showDocuments: showDocuments })
			.pipe(
				switchMap((response) => {
					const res = response as ResponseModel<boolean>;
					return of(res.data);
				}),
			);
	}


	getPotentialCarriers(): Observable<number> {
		const { URL } = this;
		return this.http
			.get(`${URL}potential-carriers`)
			.pipe(
				switchMap((response) => {
					const res = response as ResponseModel<number>;
					return of(res.data);
				}),
			);
	}
}
